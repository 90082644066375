import { type Dispatch, type SetStateAction, useState } from 'react';

/**
 * ## A simple utility for sharing state between distant components
 *
 * ### Example
 *
 * ```tsx
 * // file_1
 * export const [useColor, setColor] = sharedState('red');
 *
 * // file_2
 * import { useColor } from 'file_1';
 *
 * const ShowColorComponent = () => {
 *   const color = useColor();
 *   return <div style={{ background: color }}>{color}</div>;
 * };
 *
 * // file_3
 * import { setColor } from 'file_1';
 *
 * const SetColorComponent = () => ()
 *   <button onClick={() => setColor('blue')}>
 *     Set blue
 *   </button>
 * );
 * ```
 *
 * ### Use case
 *
 * Sharing state between distant components can be done with prop-drilling,
 * Redux, or React's Context API. The latter is often the cleanest of these
 * three and it works well in cases where context matters, namely, when the
 * state that a component inherits should depend on the ancestor it lives under
 * in the component tree.
 *
 * There are, however, other common cases where a known single piece of state
 * just needs to be shared among components regardless of their component tree
 * location. With the Context API, this might require placing a provider near
 * the root of one or many pages. The Context API can also be cumbersome to use
 * when components need to update the shared state, which may require passing a
 * setState or other callback function through the context. Examples of such
 * cases include:
 * - Inputs that need to update filter or pagination state on a page.
 * - A single modal instance, opened by various components that each pass data.
 *
 * The simple alternative provided here is inspired by
 * [signals](https://github.com/tc39/proposal-signals), which are being adopted
 * as a way to manage state by many frameworks for a variety of reasons.
 * [SolidJS](https://docs.solidjs.com/guides/state-management#managing-basic-state)
 * made signals popular as a way to handle non-VDOM rendering.
 * [Signals in Angular](https://angular.dev/guide/signals) showcase ways to
 * efficiently handle computed values. Most relevantly,
 * [Preact signals](https://preactjs.com/blog/introducing-signals/)
 * highlight a simple and efficient way to share state.
 *
 * If we only need a shared state utility, we can use something similar to
 * Preact signals, but even simpler. The `sharedState` function provided here
 * simply returns a hook to access a shared value and a setter to update it.
 *
 * This is not meant to fully replace the Context API, but rather to be used in
 * cases when location in the component tree does not matter and/or when state
 * needs to be declared in one place but updated from another.
 */
export function sharedState<T>(
  value: T
): [SharedStateHook<T>, SharedStateSetter<T>] {
  const componentRenderTriggers = new Set<Dispatch<SetStateAction<symbol>>>();

  const initSymbol = Symbol('init-symbol');

  /*
   * When this hook is used in a component, it gets and keeps a setter from
   * useState which can be used to let React know when that component needs
   * needs to re-render. These are kept in a Set becuase useState will return
   * the same exact setter across multiple renders. This means that that we can
   * call .add on multiple renders without risk of duplication.
   */
  const useValue: SharedStateHook<T> = () => {
    componentRenderTriggers.add(useState(initSymbol)[1]);
    return value;
  };

  /*
   * Update the value and call each useState setter to let React know that the
   * associated components should be re-rendered with the new value.
   */
  const setValue: SharedStateSetter<T> = (newValue: T) => {
    if (typeof window === 'undefined') {
      throw new Error(
        'Shared state is only meant to be updated from the client'
      );
    }
    if (value === newValue) {
      return;
    }
    value = newValue;
    const updateSymbol = Symbol('update-symbol');
    for (const t of componentRenderTriggers) {
      t(updateSymbol);
    }

    /*
     * We need to clean up triggers for components that have been removed. We
     * can actually clear the entire Set here because any component using this
     * state will re-add itself via useValue on the render that we just queued.
     *
     * React will skip re-rendering if the value is set to the same value that
     * was used in the last render. The (value === newValue) check does not
     * mitigate this if setValue is first called with a new value and then
     * called with the previous value before the next render. Therefore, we
     * need to use a new Symbol to ensure that we still cause a render and
     * rebuild the Set in that edge case.
     */
    componentRenderTriggers.clear();
  };

  return [useValue, setValue];
}

/**
 * Use this hook to access the value. Your component will re-render with each
 * update to the value, just as it would with `useState()`.
 * ```tsx
 * // file_1
 * export const [useColor, setColor] = sharedState('red');
 *
 * // file_2
 * import { useColor } from 'file_1';
 *
 * const ShowColorComponent = () => {
 *   const color = useColor();
 *   return <div style={{ background: color }}>{color}</div>;
 * };
 * ```
 */
export type SharedStateHook<T> = () => T;

/**
 * Set a new value. Any component using the hook for this state will re-render
 * with the updated value.
 * ```tsx
 * // file_1
 * export const [useColor, setColor] = sharedState('red');
 *
 * // file_2
 * import { setColor } from 'file_1';
 *
 * const SetColorComponent = () => (
 *   <button onClick={() => setColor('blue')}>
 *     Set blue
 *   </button>
 * );
 * ```
 */
export type SharedStateSetter<T> = (value: T) => void;
