import { getCatalogPath } from '@mono/util-url';

import certificationPrepIllustration from './assets/certificationPrepIllustration.svg';
import { SortBy } from './PaginatedCatalog/SortByDropdown/types';

export const catalogHomePageName = 'home_catalog';

export const aiSubject = {
  title: 'AI',
  slug: 'artificial-intelligence',
  type: 'Category' as const,
  categoryType: 'subject',
};

export const certificationPrepTopic = {
  title: 'Certification prep',
  slug: 'certification-prep',
  type: 'Category' as const,
};

export const certificationPrepSpotlightContent = {
  eyebrow: 'New',
  header: 'Prepare for top industry certifications',
  description:
    'Earning industry certifications is a great way to continue growing your career and stand out in a competitive job market. Now you have one easy place to browse to find your next certification prep path. With realistic practice exams and skill benchmarking, you’ll learn everything you need to know to ace top certification tests from AWS, Microsoft, CompTIA, and more.',
  slug: 'certification-prep',
  cta: {
    text: 'Browse certification prep paths',
    href: getCatalogPath({
      categoryType: 'certification-prep',
    }),
  },
  imageSrc: certificationPrepIllustration,
};

export const CATALOG_PAGE_SIZE = 48;

export const DEFAULT_SORT_BY = SortBy.POPULAR;

export const featuredContainerSlugs = [
  'introduction-to-cybersecurity',
  'full-stack-engineer-career-path',
  'code-foundations',
  'learn-html',
  'computer-science',
  'learn-how-to-build-websites',
  'intro-to-chatgpt',
  'bi-data-analyst',
  'analyze-data-with-sql',
  'introduction-to-javascript',
  'front-end-engineer-career-path',
  'machine-learning-ai-engineering-foundations',
];

export const anonAIStaticPrompts = [
  "I'm new to coding, what courses should I take?",
  'What can I learn for my career?',
  'What projects can help me showcase my skills?',
  'How can I earn certificates?',
];
