import { Box, Text, TextButton } from '@codecademy/gamut';
import { CheckerRegular } from '@codecademy/gamut-patterns';
import styled from '@emotion/styled';
import { useTracking } from '@mono/data-use-tracking';

interface NoFilterResultsProps {
  clearFilterCallback: () => void;
}

const Pattern = styled(CheckerRegular)`
  width: 100%;
  height: 100%;
  z-index: -999;
`;

export const NO_FILTER_MATCHES_COPY =
  'There are currently no results that match these filters.';

export const NoFilterResults: React.FC<NoFilterResultsProps> = ({
  clearFilterCallback,
}) => {
  const { trackUserClick } = useTracking();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="22rem"
      position="relative"
    >
      <Pattern position="absolute" />
      <Box
        bg="beige"
        height="calc(100% - 2.5rem)"
        width="calc(100% - 3rem)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={16}
      >
        <Text textAlign="center" pb={16}>
          {NO_FILTER_MATCHES_COPY}
        </Text>
        <TextButton
          onClick={() => {
            clearFilterCallback();
            trackUserClick({
              target: 'clear_filters',
              context: 'no_results_placeholder',
            });
          }}
        >
          Clear filters
        </TextButton>
      </Box>
    </Box>
  );
};
