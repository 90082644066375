import { logError } from '@mono/data-logging';

const logAnonChatError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
  endpoint: string
) => {
  const message =
    response.message || response.error || JSON.stringify(response);
  const status = response.error.status || undefined;

  logError(response.error, {
    endpoint,
    feature: 'anon-chat',
    message,
    status,
  });
};

export interface AnonChatResponse {
  id: string;
  role: string;
  content: string;
  timestamp: string;
}

// TODO: remove user auth once ready
export const anonChat = async ({
  body,
}: {
  body: string;
}): Promise<[AnonChatResponse | null, Error | null]> => {
  try {
    const res = await fetch(`/api/portal/anon-chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    const response = await res.json();

    if (!res.ok) {
      if (res.status === 429) {
        return [null, new Error('Rate limit error')];
      }
      logAnonChatError(response, 'chat');
      return [null, new Error(response.error)];
    }

    return [response, null];
  } catch (error) {
    logAnonChatError({ error, message: 'Unable to fetch anon-chat' }, 'chat');
    return [null, error as Error];
  }
};
