import {
  Anchor,
  Box,
  ContentContainer,
  FeatureShimmer,
  FlexBox,
  IconButton,
  Input,
  Text,
  TextButton,
  TextButtonProps,
} from '@codecademy/gamut';
import { SendIcon, SparkleIcon } from '@codecademy/gamut-icons';
import { DotRegular } from '@codecademy/gamut-patterns';
import { ColorMode, css, states, variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { useCallback, useState } from 'react';

import { createUserMessage } from './AnonAIAssistant/message';
import { setAnonAIOpen, setInjectedMessage } from './AnonAIAssistant/state';
import { anonAIStaticPrompts } from './CatalogHome/const';

const HomePromptButton = styled(TextButton)`
  justify-content: left;
  padding: 6px 8px;
  height: auto;
  background: var(--color-white-100);
  position: relative;
  border-width: 1px;
  border-color: var(--color-white-400);
  white-space: normal;
  text-align: left;
  width: 100%;
  :not(:focus-visible) {
    color: var(--color-white);
  }
  :before {
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 4px;
  }
`;

const HubPromptButton = styled(TextButton)`
  justify-content: left;
  padding: 6px 8px;
  height: auto;
  background: var(--color-navy-100);
  border-width: 1px;
  border-color: var(--color-navy-400);
  white-space: normal;
  text-align: left;
  width: 100%;
  :not(:focus-visible) {
    color: var(--color-navy);
  }
  :before {
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 4px;
  }
`;

export const ChatboxPromptButton = styled(TextButton)<{
  variant: 'standalone' | 'withinMessage';
}>(
  variant({
    base: {
      justifyContent: 'left',
      padding: '8px 16px',
      fontWeight: 'normal',
      height: 'auto',
      borderWidth: '1px',
      whiteSpace: 'normal',
      textAlign: 'left',
      width: '100%',
      ':not(:focus-visible)': {
        color: { _: 'navy' },
      },
      ':before': {
        top: '-1px',
        left: '-1px',
        bottom: '-1px',
        right: '-1px',
        borderRadius: 'md',
      },
    },
    variants: {
      standalone: {
        background: 'var(--color-navy-100)',
        ':hover': {
          background: 'var(--color-navy-200)',
        },
      },
      withinMessage: {
        background: 'var(--color-navy-200)',
        ':hover': {
          background: 'var(--color-navy-300)',
        },
      },
    },
  })
) as (
  // make variant refer to the variant defined here rather than variant on TextButtonProps
  props: Omit<TextButtonProps, 'variant'> & {
    variant: 'standalone' | 'withinMessage';
  }
) => JSX.Element;

const AnonAIInput = styled(Input)`
  border: none;
  background: var(--color-white);
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  + * svg {
    color: var(--color-text-secondary);
  }
  :focus {
    box-shadow: none;
    outline-offset: 2px;
    outline: 2px solid var(--color-yellow);
  }
`;

const SendBtn = styled(IconButton)<{ isEnabled?: boolean }>(
  css({
    '&:before': {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderColor: 'hyper',
      borderRadius: 'md',
    },
  }),
  states({
    isEnabled: {
      bg: 'primary-hover',
      color: 'black',
      '&:hover': {
        color: 'black',
      },
    },
  })
);

const AnonAIInputBox = ({
  mobile,
  placeholder,
}: {
  mobile: boolean;
  placeholder: string;
}) => {
  const [message, setMessage] = useState('');
  const messageTrimmed = message.trim();
  const enabled = messageTrimmed.length;

  const send = useCallback(() => {
    setInjectedMessage(createUserMessage(messageTrimmed));
    setMessage('');
    setAnonAIOpen(true);
  }, [messageTrimmed]);

  return (
    <ColorMode
      mt={24}
      mode="light"
      width={{ _: '100%', sm: 512, md: 640, xl: 768 }}
      display={
        mobile
          ? { _: 'inline-block', xs: 'none' }
          : { _: 'none', xs: 'inline-block' }
      }
    >
      <FlexBox>
        <Box width="100%">
          <AnonAIInput
            placeholder={placeholder}
            type="text"
            value={message}
            onChange={(evt) => setMessage(evt.target.value)}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter' && !evt.shiftKey) {
                evt.preventDefault();
                if (enabled) {
                  send();
                }
              }
            }}
          />
        </Box>
        <FlexBox
          alignItems="center"
          justifyContent="center"
          height={40}
          ml={-40 as 0}
          width={40}
        >
          <SendBtn
            icon={SendIcon}
            data-testid="generate-button"
            type="submit"
            tip="Submit message"
            onClick={() => enabled && send()}
            disabled={!enabled}
          />
        </FlexBox>
      </FlexBox>
    </ColorMode>
  );
};

export const AnonAICatalogHomePrompts = ({
  quizHref,
}: {
  quizHref: string;
}) => (
  <FlexBox
    bg="navy"
    overflow="clip"
    position="relative"
    color="white-600" // this sets the color of the pattern
    mb={32}
  >
    <DotRegular position="absolute" top={0} left={0} right={0} bottom={0} />

    <ContentContainer zIndex={2} py={32}>
      <ColorMode
        mode="dark"
        display="flex"
        bg="navy"
        p={16}
        flexDirection="column"
        alignItems="center"
      >
        <Text as="h1" fontSize={{ _: 34, sm: 44 }}>
          Explore the catalog
        </Text>
        <Text
          variant="p-large"
          mt={8}
          textAlign="center"
          maxWidth={{ _: 320, sm: 512 }}
          fontSize={{ _: 16, xs: 18 }}
        >
          Start a conversation and find learning to match your goals.
        </Text>
        <FeatureShimmer mt={24}>
          <FlexBox
            as="ul"
            flexDirection={{ _: 'column', sm: 'row' }}
            justifyContent="center"
            mb={0}
            p={0}
            width={{ _: '100%', sm: 640, xl: '100%' }}
            gap={12}
            flexWrap="wrap"
            gridTemplateColumns="1fr 1fr"
          >
            {anonAIStaticPrompts.map((prompt) => (
              <Box key={prompt} as="li" listStyleType="none" display="block">
                <HomePromptButton
                  size="small"
                  onClick={() => {
                    setInjectedMessage(createUserMessage(prompt));
                    setAnonAIOpen(true);
                  }}
                >
                  <SparkleIcon
                    aria-hidden
                    mr={8}
                    width={12}
                    height={12}
                    color="blue-400"
                  />
                  {prompt}
                </HomePromptButton>
              </Box>
            ))}
          </FlexBox>
        </FeatureShimmer>

        <AnonAIInputBox mobile placeholder="Ask our AI Learning Assistant" />
        <AnonAIInputBox
          mobile={false}
          placeholder="Ask your question for our AI Learning Assistant"
        />

        <Text fontFamily="accent" variant="p-small" mt={32}>
          <Text>Not sure where to begin?&nbsp;</Text>
          <Anchor
            variant="interface"
            fontWeight="bold"
            aria-label="Take our quiz"
            href={quizHref}
          >
            Take our quiz &rarr;
          </Anchor>
        </Text>
      </ColorMode>
    </ContentContainer>
  </FlexBox>
);

export const AnonAICatalogHubPrompts = ({ prompts }: { prompts: string[] }) => (
  <FlexBox
    as="ul"
    m={0}
    p={0}
    flexWrap="wrap"
    gap={16}
    flexDirection={{ _: 'column', sm: 'row' }}
  >
    {prompts.map((prompt) => (
      <Box key={prompt} as="li" listStyleType="none" display="block">
        <HubPromptButton
          size="small"
          onClick={() => {
            setInjectedMessage(createUserMessage(prompt));
            setAnonAIOpen(true);
          }}
        >
          <SparkleIcon
            aria-hidden
            mr={8}
            width={12}
            height={12}
            color="blue-500"
          />
          {prompt}
        </HubPromptButton>
      </Box>
    ))}
  </FlexBox>
);

export const AnonAIChatboxPrompts = ({
  onPromptSelect,
}: {
  onPromptSelect: () => void;
}) => (
  <FlexBox as="ul" m={0} p={0} gap={16} flexDirection="column">
    {anonAIStaticPrompts.map((prompt) => (
      <Box key={prompt} as="li" listStyleType="none" display="block">
        <ChatboxPromptButton
          onClick={() => {
            setInjectedMessage(createUserMessage(prompt));
            onPromptSelect();
          }}
          variant="standalone"
        >
          <SparkleIcon
            aria-hidden
            mr={8}
            width={12}
            height={12}
            color="blue-500"
          />
          {prompt}
        </ChatboxPromptButton>
      </Box>
    ))}
  </FlexBox>
);
