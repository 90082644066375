import {
  PathGoalEnum,
  RawExtCourseFragment,
} from '@mono/data-codegen/client/graphql-gateway/graphql';
import { getTimeToComplete } from '@mono/data-curriculum-cards';

import { DurationInfo, RawPath, RawTrack } from './types';

export const getSEODuration = (durationHours: number) => `PT${durationHours}H`;

// Ported from the monolith https://github.com/codecademy-engineering/Codecademy/blob/7a7f48928b041025d98a390e0bb7c35e7a5fe357/webpack/assets/portal/state/entities/contentItems/selectors.ts#L87
export const fallbackDurationRounder = (duration: number) => {
  // Round any values over 10 down to the nearest five hours (eg. 16 hours -> 15 hours)
  // Round any values under 10 to the nearest 1 hour (eg. 6.5 -> 6).
  // Under 1 hour should round to 1.

  if (duration > 10) {
    return Math.floor(duration / 5) * 5;
  }
  if (duration > 1) {
    return Math.floor(duration);
  }
  return 1;
};

type ContentItemsCount = {
  lessonCount: number;
  projectCount: number;
  kanbanProjectCount: number;
  articleCount: number;
  quizCount: number;
  videoCount: number;
  externalResourceCount: number;
};

// Ported from the monolith https://github.com/codecademy-engineering/Codecademy/blob/7a7f48928b041025d98a390e0bb7c35e7a5fe357/webpack/assets/portal/state/entities/contentItems/selectors.ts#L102
export const getFallbackDurationHours = ({
  lessonCount,
  projectCount,
  kanbanProjectCount,
  articleCount,
  quizCount,
  videoCount,
  externalResourceCount,
}: ContentItemsCount) => {
  const duration = fallbackDurationRounder(
    (lessonCount * 45 +
      projectCount * 60 +
      kanbanProjectCount * 60 +
      (articleCount + quizCount + videoCount + externalResourceCount) * 10) /
      60
  );
  return duration;
};

export const getTrackPathDurationInfo = (
  rawContainer: RawTrack | RawPath
): DurationInfo => {
  return rawContainer.metrics?.medianDurationHours
    ? {
        isMetricDerived: true,
        finalizedHours: getTimeToComplete(
          rawContainer.metrics.medianDurationHours,
          'goal' in rawContainer && rawContainer.goal === PathGoalEnum.Career
        ),
      }
    : {
        isMetricDerived: false,
        finalizedHours: getFallbackDurationHours({ ...rawContainer }),
      };
};

export const getExtCourseDurationInfo = (
  rawExtCourse: RawExtCourseFragment
): DurationInfo => {
  return {
    isMetricDerived: false,
    finalizedHours: getTimeToComplete(rawExtCourse.durationHours),
  };
};
